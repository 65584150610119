import { useEffect, useState } from "react";
import styles from "./Coupon.module.css";
import { useNavigate, useParams } from "react-router-dom";
import phoneImage from "../../images/phone.svg";
import apiService, { HttpError } from "components/provider/rest";

function Coupon() {
  const navigate = useNavigate();
  const [isUse, setIsUse] = useState(false);
  const couponId = useParams().coupon_id;
  const [couponData, setCouponData] = useState({
    id: "",
    name: "",
    targetName: "",
    discountType: "",
    discountAmount: undefined,
    discountPercentage: undefined,
    couponDiscountAmount: 0,
    showDiscountAmount: false,
    isRigakuBody: false,
    expireTime: "",
    minAmount: 0,
    couponMasterID: "",
    precautions: [],
    salonData: {
      name: "",
      address: "",
    },
  });

  useEffect(() => {
    (async () => {
      const res = await apiService
        .post("/public/coupon/get", {
          coupon_id: couponId,
        })
        .catch((err: HttpError) => {
          if (err.statusCode === 404) {
            navigate("/notFound");
          } else {
            alert(
              "エラーが発生しました。しばらく経ってから再度お試しください。"
            );
          }
        })
        .catch(() => {
          alert("エラーが発生しました。しばらく経ってから再度お試しください。");
        });
      setCouponData({
        id: res.id,
        name: res.name,
        targetName: res.target_name,
        showDiscountAmount: res.show_discount_amount,
        discountType: res.discount_amount ? "fixed" : "percentage",
        discountAmount: res.discount_amount,
        discountPercentage: res.discount_percent,
        couponDiscountAmount: res.coupon_discount_amount,
        isRigakuBody: res.is_rigaku_body,
        expireTime: res.expire_time,
        minAmount: res.min_amount,
        couponMasterID: res.coupon_master_id,
        precautions: res.precautions,
        salonData: {
          name: res.salon_data.name,
          address: res.salon_data.address,
        },
      });
      if (res.used_time) {
        setIsUse(true);
      }
    })();
  }, [couponId, navigate]);

  const useCoupon = async () => {
    let message = "クーポンを使用しますか？";
    if (couponData.isRigakuBody) {
      message = "施術券を使用しますか？";
    }
    if (window.confirm(message)) {
      await apiService
        .post("/public/coupon/use", {
          coupon_id: couponId,
        })
        .then(() => {
          setIsUse(true);
        })
        .catch(() => {
          alert("エラーが発生しました。しばらく経ってから再度お試しください。");
        });
    }
  };

  const couponName = () => {
    // FIXME: 暫定対応
    // ref: https://wssouhl.slack.com/archives/C06FZ03DW0Z/p1724904208172409?thread_ts=1724904077.264209&cid=C06FZ03DW0Z
    if (couponData.couponMasterID === "01HSZE4HZ99GDB67QVWCXQXKYN") {
      return (
        <div>
          <p>「1000円OFFクーポン」</p>
          <p>もしくは</p>
          <p>「小鼻のハイドラ毛穴洗浄orラジオ波 3000円相当」</p>
        </div>
      );
    }
    return <span>{couponData.name}</span>;
  };

  return (
    <div className={styles.main_content}>
      <div className={styles.coupon_ticket}>
        <p className={styles.coupon_name}>{couponName()}</p>
      </div>
      <p className={styles.how_to_use_text}>
        <img src={phoneImage} alt="phone" className={styles.phone_image} />
        この画面を店舗に提示して使用します
      </p>
      <h3 className={styles.card_title}>
        {couponData.isRigakuBody ? "施術券内容" : "クーポン内容"}
      </h3>
      <div className={styles.card}>
        <table className={styles.payment_detail_table}>
          <tbody>
            <tr>
              <td className={styles.td_left}>特典ID</td>
              <td className={styles.td_right}>{couponData.id}</td>
            </tr>
            <tr>
              <td className={styles.td_left}>割引対象</td>
              <td className={styles.td_right}>{couponData.targetName}</td>
            </tr>
            {couponData.showDiscountAmount && (
              <tr>
                <td className={styles.td_left}>割引金額</td>
                <td className={styles.td_right}>
                  ￥{couponData.couponDiscountAmount}
                </td>
              </tr>
            )}
            <tr>
              <td className={styles.td_left}>ご利用方法</td>
              <td className={styles.td_right}>
                {couponData.isRigakuBody
                  ? "次回来店時に施術券提示"
                  : "次回来店時にクーポン提示"}
              </td>
            </tr>
            {!couponData.isRigakuBody && (
              <tr>
                <td className={styles.td_left}>有効期限</td>
                <td className={styles.td_right}>{couponData.expireTime}まで</td>
              </tr>
            )}
            <tr>
              <td className={styles.td_left}>利用回数</td>
              <td className={styles.td_right}>期限内で1回限り有効</td>
            </tr>
            <tr>
              <td className={styles.td_left}>最低利用金額</td>
              <td className={styles.td_right}>￥{couponData.minAmount}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h3 className={styles.card_title}>注意事項</h3>
      <div className={styles.card}>
        <ul className={styles.notice_ul}>
          {couponData?.precautions?.map((precaution, _) => (
            <li>{precaution}</li>
          ))}
        </ul>
      </div>
      <h3 className={styles.card_title}>発行元</h3>
      <div className={styles.card}>
        <p>
          {couponData.salonData.name}
          <br />
          {couponData.salonData.address}
        </p>
      </div>
      {isUse ? (
        <div className={styles.coupon_button_div}>
          <div className={styles.button_text}>
            <p className={styles.thanks}>ご利用ありがとうございました</p>
          </div>
          <button className={`common-button ${styles.used_button}`} disabled>
            クーポンは使用済みです
          </button>
        </div>
      ) : (
        <div className={styles.coupon_button_div}>
          <div className={styles.button_text}>
            <p className={styles.button_text_large}>
              店舗の方がボタンを押してください
            </p>
            <p className={styles.button_text_small}>
              店舗の方にこの画面を表示してボタンを押して
              もらうことでクーポンが利用できます
            </p>
          </div>
          <button className="common-button" onClick={useCoupon}>
            使用済みにする
          </button>
        </div>
      )}
    </div>
  );
}

export default Coupon;
