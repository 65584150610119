import { showEditButton } from "domain/home";
import {
  convertCancelTypeToString,
  convertStringToCancelType,
} from "domain/cancel";
import ActionButton from "./ActionButton/ActionButton";
import { EditingRow, Tab } from "../HomePage.logic";
import styles from "./CancelsTableView.module.scss";
import {
  STATUS,
  getCouponMasterSelectDefaultValue,
  getInvoicePrice,
  getReserveId,
  isEditablePaymentStatus,
  renderVisitCount,
  showCheckbox,
} from "./CancelsTableView.common.logic";
import {
  CancelForListFragment,
  CancelPaymentStatus,
  CancelType,
  CouponMasterForListFragment,
  CouponMasterNameFragment,
} from "gql/__generated__/graphql";
import CouponMasterSelect from "./CouponMasterSelect/CouponMasterSelect";
import { useMemo } from "react";
import {
  convertDateToHumanReadableDate,
  convertGraphQLDateToDate,
  formatDateUntilDayByHyphen,
} from "utils/date";

// ヘッダー
export type Props = {
  currentTab: Tab;
  cancels: readonly CancelForListFragment[];
  editingRowMap: Record<string, EditingRow>; // key is idv2
  checkedCancelMap?: Record<number, CancelForListFragment>; // key is cancelId
  availableCouponMasters: readonly CouponMasterForListFragment[];
  selectableCancelTypes: CancelType[];
  enableDefaultCouponMasterForNoConfirmationCancel: boolean;
  defaultCouponMaster?: CouponMasterNameFragment;
  updateRow: (idv2: string, row: EditingRow) => void;
  onEditOriginPrice: (idv2: string, newOriginPriceStr: string) => Promise<void>;
  onEditCancelType: (idv2: string, cancelType: CancelType) => Promise<void>;
  onClickUpdateButton: (idv2: string) => void;
  onClickActionButton: (idv2: string) => void;
  cancelRowEdit: (idv2: string) => void;
  onClickRowEdit: (idv2: string) => void;
  onClickCheckbox?: (cancel: CancelForListFragment) => void;
};

export const CancelsTableView = ({
  currentTab,
  cancels,
  editingRowMap,
  checkedCancelMap,
  availableCouponMasters,
  selectableCancelTypes,
  enableDefaultCouponMasterForNoConfirmationCancel,
  defaultCouponMaster,
  updateRow,
  onEditOriginPrice,
  onEditCancelType,
  onClickUpdateButton,
  onClickActionButton,
  cancelRowEdit,
  onClickRowEdit,
  onClickCheckbox,
}: Props) => {
  const isEditingRow = (idv2: string): boolean => {
    return idv2 in editingRowMap;
  };
  const showPaidTime = currentTab === "PAID";

  const tableHeaders: string[][] = useMemo(() => {
    const headers = [
      ["ステータス"],
      ["予約ID"],
      ["キャンセル", "種別"],
      ["来店回数"],
      ["予約金額"],
      ["キャンセル", "料金"],
      ["来店予定日"],
      ["キャンセル", "処理日"],
    ];
    if (showPaidTime) {
      headers.push(["回収日"]);
    }
    headers.push(["担当", "スタッフ"]);
    headers.push(["請求先"]);
    headers.push(["電話番号"]);
    headers.push(["アクション"]);
    headers.push(["特典"]);
    headers.push(["編集"]);

    return headers;
  }, [showPaidTime]);

  return (
    <div>
      <div className={styles.table_container}>
        <table className={styles.scroll_table}>
          <thead>
            <tr>
              <th></th>
              {tableHeaders.map((header: string[], index) => {
                return (
                  <th key={index}>
                    {header.map((text, i) => {
                      return <div key={i}>{text}</div>;
                    })}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {cancels.map((row, _) => (
              <tr key={row.idv2}>
                <td>
                  <input
                    type="checkbox"
                    style={{
                      visibility: showCheckbox(row.paymentStatus)
                        ? "visible"
                        : "hidden",
                    }}
                    checked={checkedCancelMap && row.id in checkedCancelMap}
                    onClick={() => {
                      if (onClickCheckbox) {
                        onClickCheckbox(row);
                      }
                    }}
                    onChange={() => {}}
                  />
                </td>
                <td key={`${row.idv2}-status`}>
                  {(() => {
                    let spanClass = "";
                    if (row.paymentStatus === "INVOICE_NOT_SENT")
                      spanClass = styles.background_light_orange;
                    else if (row.paymentStatus === "INVOICE_SENT")
                      spanClass = styles.background_pink;
                    else if (row.paymentStatus === "PAID")
                      spanClass = styles.background_blue;
                    else if (row.paymentStatus === "NOT_SUBJECT")
                      spanClass = styles.background_gray;
                    else if (row.paymentStatus === "CLAIM_ABORTED")
                      spanClass = styles.background_red;
                    else if (row.paymentStatus === "SCHEDULED_TO_CLAIM")
                      spanClass = styles.background_light_green;
                    return (
                      <span
                        className={`${spanClass} ${styles.table_elm_background}`}
                      >
                        {STATUS[row.paymentStatus]}
                      </span>
                    );
                  })()}
                </td>
                <td>{getReserveId(row)}</td>
                <td key={`${row.idv2}-cancelType`}>
                  {isEditingRow(row.idv2) ? (
                    <select
                      value={convertCancelTypeToString(
                        editingRowMap[row.idv2].cancelType
                      )}
                      onChange={(e) => {
                        onEditCancelType(
                          row.idv2,
                          convertStringToCancelType(e.target.value)
                        );
                      }}
                      className={styles.cancel_type_select}
                    >
                      {selectableCancelTypes.map((cancelType) => (
                        <option value={convertCancelTypeToString(cancelType)}>
                          {convertCancelTypeToString(cancelType)}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <span>{convertCancelTypeToString(row.cancelType)}</span>
                  )}
                </td>
                <td key={`${row.idv2}-visits`}>{renderVisitCount(row)}</td>
                <td key={`${row.idv2}-amount`}>
                  {isEditingRow(row.idv2) ? (
                    <input
                      className={styles.editing_amount}
                      type="number"
                      value={editingRowMap[row.idv2].originPrice}
                      onChange={(event) => {
                        onEditOriginPrice(row.idv2, event.target.value);
                      }}
                    />
                  ) : (
                    <span>¥{row.originPrice}</span>
                  )}
                </td>
                <td key={`${row.idv2}-invoice-price`}>
                  ¥{getInvoicePrice(row, isEditingRow(row.idv2), editingRowMap)}
                </td>
                <td key={`${row.idv2}-scheduledDate`}>{row.dateVisit}</td>
                <td key={`${row.idv2}-cancelDate`}>{row.dateCancel ?? "不明"}</td>

                {showPaidTime && (
                  <td key={`${row.idv2}-paidTime`}>
                    {row.paidTime
                      ? formatDateUntilDayByHyphen(
                          convertGraphQLDateToDate(row.paidTime)
                        )
                      : ""}
                  </td>
                )}

                <td key={`${row.idv2}-staff`}>{row.staffName ?? ""}</td>
                <td key={`${row.idv2}-billingTo`}>
                  {isEditingRow(row.idv2) ? (
                    <input
                      className={styles.editing_name}
                      type="text"
                      value={editingRowMap[row.idv2].name}
                      onChange={(event) => {
                        const newRow: EditingRow = {
                          ...editingRowMap[row.idv2],
                          name: event.target.value,
                        };
                        updateRow(row.idv2, newRow);
                      }}
                    />
                  ) : (
                    <span>{row.name}</span>
                  )}
                </td>
                <td key={`${row.idv2}-phoneNumber`}>
                  {isEditingRow(row.idv2) ? (
                    <input
                      className={styles.editing_phone_number}
                      type="number"
                      value={editingRowMap[row.idv2].phoneNumber}
                      onChange={(event) => {
                        const newRow: EditingRow = {
                          ...editingRowMap[row.idv2],
                          phoneNumber: event.target.value,
                        };
                        updateRow(row.idv2, newRow);
                      }}
                    />
                  ) : (
                    <span>{row.phoneNumber}</span>
                  )}
                </td>
                <td key={`${row.idv2}-action`}>
                  <ActionButton
                    row={row}
                    isEditingRow={isEditingRow(row.idv2)}
                    onClickUpdateButton={() => onClickUpdateButton(row.idv2)}
                    onClickActionButton={() => onClickActionButton(row.idv2)}
                  />
                </td>
                <td key={`${row.idv2}-coupon`}>
                  <CouponMasterSelect
                    editingRow={editingRowMap[row.idv2]}
                    defaultValue={getCouponMasterSelectDefaultValue(
                      currentTab,
                      row,
                      enableDefaultCouponMasterForNoConfirmationCancel,
                      defaultCouponMaster
                    )}
                    availableCouponMasters={availableCouponMasters}
                    isEditablePaymentStatus={isEditablePaymentStatus(
                      row.paymentStatus
                    )}
                    updateRow={(id) => {
                      const newRow: EditingRow = {
                        ...editingRowMap[row.idv2],
                        couponMasterId: id,
                      };
                      updateRow(row.idv2, newRow);
                    }}
                    onClickSelectButton={() => onClickRowEdit(row.idv2)}
                  />
                </td>
                <td key={`${row.idv2}-edit`}>
                  {row.paymentStatus !== "NOT_SUBJECT" &&
                    showEditButton(row.paymentStatus) &&
                    (isEditingRow(row.idv2) ? (
                      <a href="#" onClick={() => cancelRowEdit(row.idv2)}>
                        編集
                        <br />
                        取消
                      </a>
                    ) : (
                      <a href="#" onClick={() => onClickRowEdit(row.idv2)}>
                        編集
                      </a>
                    ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <p className={styles.result}>{cancels.length}件の結果</p>
    </div>
  );
};

export default CancelsTableView;
