import { CancelForRereservationFragment } from "gql/__generated__/graphql";
import { EditingRereservationRow, Tab } from "../HomePage.logic";
import { useMemo } from "react";
import styles from "./CancelsForRereservationTableView.module.scss";
import {
  getReserveId,
  renderVisitCount,
} from "./CancelsTableView.common.logic";
import { convertCancelTypeToString } from "domain/cancel";
import {
  convertDateToHumanReadableDate,
  convertGraphQLDateToDate,
  formatDateUntilDayByHyphen,
} from "utils/date";
import ActionButton from "./ActionButton/ActionButton";
import {
  canEdit,
  formatMenusAndCoupons,
  getSpanClass,
  rereservationStatusText,
} from "./CancelsForRereservationTableView.logic";

export type Props = {
  currentTab:
    | "ENCOURAGING_RERESERVATION_NOT_SCHEDULED"
    | "ENCOURAGING_RERESERVATION_SCHEDULED"
    | "RERESERVED";
  cancels: readonly CancelForRereservationFragment[];
  editingRereservationRowMap: Record<string, EditingRereservationRow>; // key is idv2
  updateEditingRereservationRow: (
    idv2: string,
    row: EditingRereservationRow
  ) => void;
  onClickUpdateButton: (idv2: string) => void;
  onClickActionButton: (idv2: string) => void;
  onClickRereservationRowEdit: (idv2: string) => void;
  cancelRereservationRowEdit: (idv2: string) => void;
};

const tableHeadersForNotScheduled: string[][] = [
  ["ステータス"],
  ["キャンセル", "処理日"],
  ["来店回数"],
  ["お客様"],
  ["電話番号"],
  ["アクション"],
  ["編集"],
];

const tableHeadersForScheduled: string[][] = [
  ["ステータス"],
  ["キャンセル", "処理日"],
  ["来店回数"],
  ["お客様"],
  ["電話番号"],
];

const tableHeadersForRereserved: string[][] = [
  ["ステータス"],
  ["キャンセル", "処理日"],
  ["来店回数"],
  ["再予約ID"],
  ["再予約金額"],
  ["施術時間"],
  ["施術メニュー"],
  ["再来店予定日"],
  ["担当スタッフ"],
  ["お客様"],
  ["電話番号"],
];

export const CancelsForRereservationTableView = ({
  currentTab,
  cancels,
  editingRereservationRowMap,
  updateEditingRereservationRow,
  onClickUpdateButton,
  onClickActionButton,
  onClickRereservationRowEdit,
  cancelRereservationRowEdit,
}: Props) => {
  const isEditingRow = (idv2: string): boolean => {
    return idv2 in editingRereservationRowMap;
  };

  const tableHeaders = useMemo(() => {
    if (currentTab === "ENCOURAGING_RERESERVATION_NOT_SCHEDULED") {
      return tableHeadersForNotScheduled;
    } else if (currentTab === "ENCOURAGING_RERESERVATION_SCHEDULED") {
      return tableHeadersForScheduled;
    }
    return tableHeadersForRereserved;
  }, [currentTab]);

  return (
    <div>
      <div className={styles.table_container}>
        <table className={styles.scroll_table}>
          <thead>
            <tr>
              {tableHeaders.map((header: string[], index) => {
                return (
                  <th key={index}>
                    {header.map((text, i) => {
                      return <div key={i}>{text}</div>;
                    })}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {cancels.map((row, _) => (
              <tr key={row.idv2}>
                <td key={`${row.idv2}-status`}>
                  {(() => {
                    return (
                      <span
                        className={`${getSpanClass(row, styles)} ${
                          styles.table_elm_background
                        }`}
                      >
                        {rereservationStatusText[row.rereservationStatus]}
                      </span>
                    );
                  })()}
                </td>

                <td key={`${row.idv2}-cancelDate`}>
                  {row.dateCancel ?? "不明"}
                </td>

                <td key={`${row.idv2}-visits`}>{renderVisitCount(row)}</td>

                {currentTab === "RERESERVED" && (
                  <>
                    <td>
                      {row.rereservation?.scrapingTargetSiteReserveId ?? ""}
                    </td>

                    <td>
                      {row.rereservation?.price !== null
                        ? "¥" + row.rereservation?.price
                        : ""}
                    </td>

                    <td>
                      {row.rereservation?.durationMinute
                        ? `${row.rereservation.durationMinute}分`
                        : ""}
                    </td>

                    <td className={styles.menu_cell}>
                      <span>{formatMenusAndCoupons(row)}</span>
                    </td>

                    <td>
                      {row.rereservation?.startTime
                        ? formatDateUntilDayByHyphen(
                            convertGraphQLDateToDate(
                              row.rereservation.startTime
                            )
                          )
                        : ""}
                    </td>

                    <td key={`${row.idv2}-staffName`}>
                      {row.rereservation?.staff?.name ?? ""}
                    </td>
                  </>
                )}

                <td key={`${row.idv2}-billingTo`}>
                  <span>{row.name}</span>
                </td>

                <td key={`${row.idv2}-phoneNumber`}>
                  {isEditingRow(row.idv2) ? (
                    <input
                      className={styles.editing_phone_number}
                      type="number"
                      value={editingRereservationRowMap[row.idv2].phoneNumber}
                      onChange={(event) => {
                        const newRow: EditingRereservationRow = {
                          ...editingRereservationRowMap[row.idv2],
                          phoneNumber: event.target.value,
                        };
                        updateEditingRereservationRow(row.idv2, newRow);
                      }}
                    />
                  ) : (
                    <span>{row.phoneNumber}</span>
                  )}
                </td>

                {currentTab === "ENCOURAGING_RERESERVATION_NOT_SCHEDULED" && (
                  <td key={`${row.idv2}-action`}>
                    <ActionButton
                      rereservationRow={row}
                      isEditingRow={isEditingRow(row.idv2)}
                      onClickUpdateButton={() => onClickUpdateButton(row.idv2)}
                      onClickActionButton={() => onClickActionButton(row.idv2)}
                    />
                  </td>
                )}

                {currentTab === "ENCOURAGING_RERESERVATION_NOT_SCHEDULED" && (
                  <td key={`${row.idv2}-edit`}>
                    {canEdit(row.rereservationStatus) &&
                      (isEditingRow(row.idv2) ? (
                        <a
                          href="#"
                          onClick={() => cancelRereservationRowEdit(row.idv2)}
                        >
                          編集
                          <br />
                          取消
                        </a>
                      ) : (
                        <a
                          href="#"
                          onClick={() => onClickRereservationRowEdit(row.idv2)}
                        >
                          編集
                        </a>
                      ))}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <p className={styles.result}>{cancels.length}件の結果</p>
    </div>
  );
};

export default CancelsForRereservationTableView;
