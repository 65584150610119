import {
  CancelPaymentStatus,
  CancelForListFragment,
  CouponMasterForListFragment,
  CouponMasterNameFragment,
  CancelType,
} from "gql/__generated__/graphql";
import { EditingRow, Tab } from "../HomePage.logic";
import styles from "./MobileCancelsTableView.module.scss";
import {
  convertCancelTypeToString,
  convertStringToCancelType,
} from "domain/cancel";
import { showEditButton } from "domain/home";
import {
  STATUS,
  getCouponMasterSelectDefaultValue,
  getInvoicePrice,
  getReserveId,
  isEditablePaymentStatus,
  renderVisitCount,
  showCheckbox,
  showUpdatePaymentStatusButton,
  updatePaymentStatusButtonTitle,
} from "./CancelsTableView.common.logic";
import ActionButton from "./ActionButton/ActionButton";
import _ from "lodash";
import { useRef } from "react";
import useOnScreen from "utils/hooks/useOnScreen";
import { TriangleUpIcon } from "@radix-ui/react-icons";
import CouponMasterSelect from "./CouponMasterSelect/CouponMasterSelect";
import {
  convertGraphQLDateToDate,
  formatDateUntilDayByHyphen,
} from "utils/date";

type Props = {
  currentTab: Tab;
  cancels: readonly CancelForListFragment[];
  editingRowMap: Record<string, EditingRow>; // key is row idv2
  checkedCancelMap?: Record<number, CancelForListFragment>; // key is cancelId
  availableCouponMasters: readonly CouponMasterForListFragment[];
  selectableCancelTypes: CancelType[];
  enableDefaultCouponMasterForNoConfirmationCancel: boolean;
  defaultCouponMaster?: CouponMasterNameFragment;
  updateRow: (idv2: string, row: EditingRow) => void;
  onEditCancelType: (idv2: string, cancelType: CancelType) => Promise<void>;
  onEditOriginPrice: (idv2: string, newOriginPriceStr: string) => Promise<void>;
  onClickUpdateButton: (idv2: string) => void;
  onClickActionButton: (idv2: string) => void;
  cancelRowEdit: (idv2: string) => void;
  onClickRowEdit: (idv2: string) => void;
  onClickCheckbox?: (cancel: CancelForListFragment) => void;
  onClickUpdatePaymentStatusButton?: () => void;
  onClickScrollUpTriangle?: () => void;
};

export const MobileCancelsView = ({
  currentTab,
  cancels,
  editingRowMap,
  checkedCancelMap,
  availableCouponMasters,
  selectableCancelTypes,
  enableDefaultCouponMasterForNoConfirmationCancel,
  defaultCouponMaster,
  updateRow,
  onEditCancelType,
  onEditOriginPrice,
  onClickUpdateButton,
  onClickActionButton,
  cancelRowEdit,
  onClickRowEdit,
  onClickCheckbox,
  onClickUpdatePaymentStatusButton,
  onClickScrollUpTriangle,
}: Props) => {
  const isEditingRow = (idv2: string): boolean => {
    return idv2 in editingRowMap;
  };
  const checkboxTitle = (paymentStatus: CancelPaymentStatus) => {
    switch (paymentStatus) {
      case "INVOICE_NOT_SENT":
        return "非請求へ移す";
      case "NOT_SUBJECT":
        return "未請求へ移す";
      default:
        return "";
    }
  };
  const headerRef = useRef(null);
  const headerPosition = useOnScreen(headerRef);
  const showScrollUpButton =
    headerPosition === "ABOVE_VIEWPORT" && _.size(checkedCancelMap) > 0;

  // TODO: 全てCancelsTableViewと同じロジックなので複雑なやつはそれぞれcomponent化するべき
  return (
    <div className={styles.mobile_cancels_table_view}>
      {showScrollUpButton && (
        <TriangleUpIcon
          className={styles.triangle_up_icon}
          onClick={onClickScrollUpTriangle}
        />
      )}

      <div ref={headerRef} className={styles.header}>
        <div className={styles.cancels_count}>{cancels.length}件の結果</div>
        {checkedCancelMap &&
          cancels.length > 0 &&
          showUpdatePaymentStatusButton(
            cancels[0].paymentStatus,
            checkedCancelMap
          ) && (
            <div className={styles.update_payment_status_container}>
              <span className={styles.selected_count}>
                {_.size(checkedCancelMap)}件選択
              </span>
              <button
                onClick={() => {
                  if (onClickUpdatePaymentStatusButton) {
                    onClickUpdatePaymentStatusButton();
                  }
                }}
              >
                {updatePaymentStatusButtonTitle(cancels[0].paymentStatus)}
              </button>
            </div>
          )}
      </div>

      {cancels.map((row, _) => (
        <div className={styles.item} key={row.id}>
          <div className={styles.attribute}>
            <div className={styles.label}>ステータス</div>
            <div className={styles.content}>
              {(() => {
                let spanClass = "";
                if (row.paymentStatus === "INVOICE_NOT_SENT")
                  spanClass = styles.background_light_orange;
                else if (row.paymentStatus === "INVOICE_SENT")
                  spanClass = styles.background_pink;
                else if (row.paymentStatus === "PAID")
                  spanClass = styles.background_blue;
                else if (row.paymentStatus === "NOT_SUBJECT")
                  spanClass = styles.background_gray;
                else if (row.paymentStatus === "CLAIM_ABORTED")
                  spanClass = styles.background_red;
                else if (row.paymentStatus === "SCHEDULED_TO_CLAIM")
                  spanClass = styles.background_light_green;
                return (
                  <span
                    className={`${spanClass} ${styles.table_elm_background}`}
                  >
                    {STATUS[row.paymentStatus]}
                  </span>
                );
              })()}
            </div>
          </div>

          <div className={styles.attribute}>
            <div className={styles.label}>キャンセル種別</div>
            <div className={styles.content}>
              {isEditingRow(row.idv2) ? (
                <select
                  value={convertCancelTypeToString(
                    editingRowMap[row.idv2].cancelType
                  )}
                  onChange={(e) => {
                    onEditCancelType(
                      row.idv2,
                      convertStringToCancelType(e.target.value)
                    );
                  }}
                >
                  {selectableCancelTypes.map((cancelType) => (
                    <option value={convertCancelTypeToString(cancelType)}>
                      {convertCancelTypeToString(cancelType)}
                    </option>
                  ))}
                </select>
              ) : (
                <span>{convertCancelTypeToString(row.cancelType)}</span>
              )}
            </div>
          </div>

          <div className={styles.attribute}>
            <div className={styles.label}>予約ID</div>
            <div className={styles.content}>{getReserveId(row)}</div>
          </div>

          <div className={styles.attribute}>
            <div className={styles.label}>来店回数</div>
            <div className={styles.content}>{renderVisitCount(row)}</div>
          </div>

          <div className={styles.attribute}>
            <div className={styles.label}>予約金額</div>
            <div className={styles.content}>
              {isEditingRow(row.idv2) ? (
                <input
                  className={styles.editing_cell}
                  type="number"
                  value={editingRowMap[row.idv2].originPrice}
                  onChange={(event) => {
                    onEditOriginPrice(row.idv2, event.target.value);
                  }}
                />
              ) : (
                <span>¥{row.originPrice}</span>
              )}
            </div>
          </div>

          <div className={styles.attribute}>
            <div className={styles.label}>キャンセル金額</div>
            <div className={styles.content}>
              ¥{getInvoicePrice(row, isEditingRow(row.idv2), editingRowMap)}
            </div>
          </div>

          <div className={styles.attribute}>
            <div className={styles.label}>来店予定日</div>
            <div className={styles.content}>{row.dateVisit}</div>
          </div>

          <div className={styles.attribute}>
            <div className={styles.label}>キャンセル処理日</div>
            <div className={styles.content}>{row.dateCancel ?? "不明"}</div>
          </div>

          {currentTab === "RERESERVED" && (
            <div className={styles.attribute}>
              <div className={styles.label}>回収日</div>
              <div className={styles.content}>
                {row.paidTime
                  ? formatDateUntilDayByHyphen(
                      convertGraphQLDateToDate(row.paidTime)
                    )
                  : ""}
              </div>
            </div>
          )}

          <div className={styles.attribute}>
            <div className={styles.label}>担当スタッフ</div>
            <div className={styles.content}>{row.staffName}</div>
          </div>

          <div className={styles.attribute}>
            <div className={styles.label}>請求先</div>
            <div className={styles.content}>
              {isEditingRow(row.idv2) ? (
                <input
                  className={styles.editing_cell}
                  type="text"
                  value={editingRowMap[row.idv2].name}
                  onChange={(event) => {
                    const newRow: EditingRow = {
                      ...editingRowMap[row.idv2],
                      name: event.target.value,
                    };
                    updateRow(row.idv2, newRow);
                  }}
                />
              ) : (
                <span>{row.name}</span>
              )}
            </div>
          </div>

          <div className={styles.attribute}>
            <div className={styles.label}>電話番号</div>
            <div className={styles.content}>
              {isEditingRow(row.idv2) ? (
                <input
                  className={styles.editing_cell}
                  type="number"
                  value={editingRowMap[row.idv2].phoneNumber}
                  onChange={(event) => {
                    const newRow: EditingRow = {
                      ...editingRowMap[row.idv2],
                      phoneNumber: event.target.value,
                    };
                    updateRow(row.idv2, newRow);
                  }}
                />
              ) : (
                <span>{row.phoneNumber}</span>
              )}
            </div>
          </div>

          <div className={styles.attribute}>
            <div className={styles.label}>特典</div>
            <div className={styles.content}>
              <CouponMasterSelect
                editingRow={editingRowMap[row.idv2]}
                defaultValue={getCouponMasterSelectDefaultValue(
                  currentTab,
                  row,
                  enableDefaultCouponMasterForNoConfirmationCancel,
                  defaultCouponMaster
                )}
                availableCouponMasters={availableCouponMasters}
                isEditablePaymentStatus={isEditablePaymentStatus(
                  row.paymentStatus
                )}
                updateRow={(id) => {
                  const newRow: EditingRow = {
                    ...editingRowMap[row.idv2],
                    couponMasterId: id,
                  };
                  updateRow(row.idv2, newRow);
                }}
                onClickSelectButton={() => onClickRowEdit(row.idv2)}
              />
            </div>
          </div>

          <div className={styles.footer}>
            <div className={styles.checkbox_edit_container}>
              {showCheckbox(row.paymentStatus) && (
                <div
                  className={styles.checkbox_container}
                  onClick={() => {
                    if (onClickCheckbox) {
                      onClickCheckbox(row);
                    }
                  }}
                >
                  <input
                    type="checkbox"
                    checked={checkedCancelMap && row.id in checkedCancelMap}
                    onChange={() => {}}
                  />
                  <span>{checkboxTitle(row.paymentStatus)}</span>
                </div>
              )}

              {row.paymentStatus !== "NOT_SUBJECT" &&
                showEditButton(row.paymentStatus) && (
                  <div className={styles.edit_button}>
                    {isEditingRow(row.idv2) ? (
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          cancelRowEdit(row.idv2);
                        }}
                      >
                        編集取消
                      </a>
                    ) : (
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          onClickRowEdit(row.idv2);
                        }}
                      >
                        編集
                      </a>
                    )}
                  </div>
                )}
            </div>
            {row.paymentStatus !== "NOT_SUBJECT" && (
              <div className={styles.action_button}>
                <ActionButton
                  row={row}
                  isEditingRow={isEditingRow(row.idv2)}
                  onClickUpdateButton={() => onClickUpdateButton(row.idv2)}
                  onClickActionButton={() => onClickActionButton(row.idv2)}
                />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MobileCancelsView;
