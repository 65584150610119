import { getFragmentData } from "gql/__generated__";
import {
  ReservableSlotFragment,
  DateReservableSlotsFragment,
  ReservableSlotsWithStaffChunkFragmentDoc,
  ReservableSlotFragmentDoc,
  StaffFragmentDoc,
} from "gql/public/__generated__/graphql";
import { addDate, dayEquals } from "utils/date";

export class HourMinute {
  private date: Date;
  hour: number;
  minute: number;

  constructor(hour: number, minute: number) {
    this.date = new Date();
    this.date.setHours(hour, minute, 0, 0);
    this.hour = hour;
    this.minute = minute;
  }

  // overflowする可能性があることに注意。その場合例外は投げない
  // willAddingMinuteOverflowでoverflowを確認可能
  addMinute(minute: number): HourMinute {
    const newDate = new Date(this.date.getTime() + minute * 60 * 1000);
    return new HourMinute(newDate.getHours(), newDate.getMinutes());
  }

  willAddingMinuteOverflow(minute: number): boolean {
    const newDate = new Date(this.date.getTime() + minute * 60 * 1000);
    return newDate.getDate() !== this.date.getDate();
  }

  less(hourMinute: HourMinute) {
    return (
      this.hour < hourMinute.hour ||
      (this.hour === hourMinute.hour && this.minute < hourMinute.minute)
    );
  }

  toString() {
    return (
      String(this.hour).padStart(2, "0") +
      ":" +
      String(this.minute).padStart(2, "0")
    );
  }

  // なぜかうごかない...
  // equals(hourMinute: HourMinute) {
  //   return this.hour === hourMinute.hour && this.minute === hourMinute.minute;
  // }

  // lessOrEquals(hourMinute: HourMinute) {
  //   return this.less(hourMinute) || this.equals(hourMinute);
  // }
}

export class SevenDaysReservableSlotsChunks {
  sevenDaysChunks: {
    date: Date;
    slots?: DateReservableSlotsFragment;
  }[];
  allChunks: DateReservableSlotsFragment[];

  private updateFields(startDay: Date, chunks: DateReservableSlotsFragment[]) {
    const getSlots = (date: Date) => {
      return chunks.find((chunk) => {
        const chunkDate = new Date(chunk.date);
        return dayEquals(date, chunkDate);
      });
    };

    this.sevenDaysChunks = [
      { date: startDay, slots: getSlots(startDay) },
      { date: addDate(startDay, 1), slots: getSlots(addDate(startDay, 1)) },
      { date: addDate(startDay, 2), slots: getSlots(addDate(startDay, 2)) },
      { date: addDate(startDay, 3), slots: getSlots(addDate(startDay, 3)) },
      { date: addDate(startDay, 4), slots: getSlots(addDate(startDay, 4)) },
      { date: addDate(startDay, 5), slots: getSlots(addDate(startDay, 5)) },
      { date: addDate(startDay, 6), slots: getSlots(addDate(startDay, 6)) },
    ];
  }

  constructor(startDay: Date, chunks: DateReservableSlotsFragment[]) {
    this.allChunks = chunks;
    this.sevenDaysChunks = [];
    this.updateFields(startDay, chunks);
  }

  private getMinStartTime(): HourMinute {
    let minStartTime = new HourMinute(11, 0); // 遅くても9時から表示
    this.allChunks.forEach((slots) => {
      const hourMinute = new HourMinute(
        slots.reservableStartHour,
        slots.reservableStartMinute
      );
      if (hourMinute.less(minStartTime)) {
        minStartTime = hourMinute;
      }
    });
    return minStartTime;
  }

  private getMaxStartTime(): HourMinute {
    let maxStartTime = new HourMinute(20, 0); // 早くても20時まで表示
    this.allChunks.forEach((slots) => {
      if (!slots) {
        return;
      }

      const hourMinute = new HourMinute(
        slots.reservableEndHour,
        slots.reservableEndMinute
      );
      if (maxStartTime.less(hourMinute)) {
        maxStartTime = hourMinute;
      }
    });
    return maxStartTime.addMinute(-30); // (max reservableEnd time) - 30
  }

  getStartTimes(): HourMinute[] {
    const minStartTime = this.getMinStartTime();
    const maxStartTime = this.getMaxStartTime();

    const startTimes: HourMinute[] = [];
    for (
      let hourMinute = minStartTime;
      hourMinute.less(maxStartTime);
      hourMinute = hourMinute.addMinute(30)
    ) {
      startTimes.push(hourMinute);
    }
    startTimes.push(maxStartTime);

    return startTimes;
  }

  getSlotsByDateAndStaff(
    date: Date,
    staffID: string | null
  ): ReservableSlotFragment[] {
    const slots = this.sevenDaysChunks.find((chunk) =>
      dayEquals(chunk.date, date)
    )?.slots;
    if (!slots) {
      return [];
    }

    if (!staffID) {
      return slots.reservableSlotsWithoutStaff.map((slot) =>
        getFragmentData(ReservableSlotFragmentDoc, slot)
      );
    }

    const slotsWithStaffChunks = slots.reservableSlotsWithStaffChunks.map(
      (chunk) =>
        getFragmentData(ReservableSlotsWithStaffChunkFragmentDoc, chunk)
    );
    const slotsWithStaff = slotsWithStaffChunks.find((chunk) => {
      const staff = getFragmentData(StaffFragmentDoc, chunk.staff);
      return staff.id === staffID;
    });

    return (
      slotsWithStaff?.reservableSlots.map((slot) =>
        getFragmentData(ReservableSlotFragmentDoc, slot)
      ) ?? []
    );
  }
}

// cache していないため、CPU・メモリ効率は非常に悪い
// TODO: test
export class ReservableChecker {
  private mergedSlots: ReservableSlotFragment[];
  private canReserveCanceledReservationMenusSet: boolean;
  private forceEmptyWhenCannotReserveCanceledReservationMenuSet: boolean;

  constructor(
    slots: ReservableSlotFragment[],
    date: Date,
    startTime: string,
    canReserveCanceledReservationMenusSet: boolean,
    forceEmptyWhenCannotReserveCanceledReservationMenuSet: boolean
  ) {
    this.mergedSlots = this.mergeSlots(slots);
    this.canReserveCanceledReservationMenusSet =
      canReserveCanceledReservationMenusSet;
    this.forceEmptyWhenCannotReserveCanceledReservationMenuSet =
      forceEmptyWhenCannotReserveCanceledReservationMenuSet;
  }

  // TODO: claudeに書かせた変なコードだから描き直したい
  // 特に、addMinuteでoverflowしないのか気になる
  /**
   * 予約可能な時間枠をマージする関数
   * 重複または連続する時間枠を1つにまとめる
   * @param slots マージ対象の時間枠配列
   * @returns マージ済みの時間枠配列
   */
  private mergeSlots(
    slots: ReservableSlotFragment[]
  ): ReservableSlotFragment[] {
    // 空配列の場合は早期リターン
    if (slots.length === 0) return [];

    // 開始時刻で昇順ソート
    const sortedSlots = [...slots].sort((a, b) => {
      const timeA = new HourMinute(a.startHour, a.startMinute);
      const timeB = new HourMinute(b.startHour, b.startMinute);
      return timeA.less(timeB) ? -1 : 1;
    });

    // マージ結果を格納する配列（最初の要素を追加）
    const merged: ReservableSlotFragment[] = [sortedSlots[0]];

    // 2番目の要素から順にマージを試みる
    for (let i = 1; i < sortedSlots.length; i++) {
      const current = sortedSlots[i]; // 現在処理中のスロット
      const last = merged[merged.length - 1]; // マージ済み配列の最後のスロット

      // 最後のスロットの終了時刻を計算
      const lastEnd = new HourMinute(
        last.startHour,
        last.startMinute
      ).addMinute(last.durationMinute);

      // 現在のスロットの開始時刻
      const currentStart = new HourMinute(
        current.startHour,
        current.startMinute
      );

      // スロットが重複している場合
      if (!lastEnd.less(currentStart)) {
        // 現在のスロットの終了時刻
        const currentEnd = currentStart.addMinute(current.durationMinute);
        // 最後のスロットの開始時刻
        const lastStart = new HourMinute(last.startHour, last.startMinute);

        // 現在のスロットが最後のスロットよりも後に終わる場合
        // 最後のスロットの期間を延長する
        if (lastEnd.less(currentEnd)) {
          last.durationMinute =
            current.durationMinute +
            (currentStart.hour * 60 + currentStart.minute) -
            (lastStart.hour * 60 + lastStart.minute);
        }
      } else {
        // スロットが重複していない場合は新しいスロットとして追加
        merged.push(current);
      }
    }

    return merged;
  }

  // hourMinute から durationMinute 分のslotを考えた時、これをおおうことができるmergedSlotがあるか
  isReservable(
    hourMinute: HourMinute,
    durationMinute: number,
    date: Date,
    startTime: string
  ): boolean {
    if (
      !this.canReserveCanceledReservationMenusSet &&
      this.forceEmptyWhenCannotReserveCanceledReservationMenuSet
    ) {
      return false;
    }

    if (hourMinute.willAddingMinuteOverflow(durationMinute)) {
      // 日付が変わる場合は予約不可
      return false;
    }
    const endTime = hourMinute.addMinute(durationMinute);

    // debug用
    // console.log(
    //   "isReservable",
    //   date,
    //   startTime,
    //   hourMinute.toString(),
    //   endTime.toString()
    // );

    for (const slot of this.mergedSlots) {
      const slotStart = new HourMinute(slot.startHour, slot.startMinute);
      const slotEnd = slotStart.addMinute(slot.durationMinute);

      // debug用
      // console.log("slot", slotStart.toString(), slotEnd.toString());

      if (!hourMinute.less(slotStart) && !slotEnd.less(endTime)) {
        return true;
      }
    }
    return false;
  }
}
