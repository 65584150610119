import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import styles from "./RereservationPage.module.scss";
import ReservableSlots from "./ReservableSlots/ReservableSlots";
import {
  CreateRereservationDocument,
  CreateRereservationMutation,
  CreateRereservationMutationVariables,
  DateReservableSlotsFragment,
  DateReservableSlotsFragmentDoc,
  GetCanceledReservationDocument,
  GetCanceledReservationQuery,
  GetCanceledReservationQueryVariables,
  GetSevenDaysReservableSlotsChunksDocument,
  GetSevenDaysReservableSlotsChunksQuery,
  GetSevenDaysReservableSlotsChunksQueryVariables,
  StaffFragmentDoc,
} from "gql/public/__generated__/graphql";
import { getFragmentData } from "gql/__generated__";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  formatDateUntilDay,
  formatDateUntilMinute,
  getJSTISOString,
  getWeekDay,
} from "utils/date";
import { getGraphQLDateString } from "domain/graphql";
import Select, { Option } from "./Select/Select";

const getNoMenuText = (durationMinuteForEmptyMenu: number) => {
  return `(${durationMinuteForEmptyMenu}分) メニュー選択なし`;
};
const getMenuNameTexts = (
  names: string[],
  durationMinute?: number,
  price?: number
) => {
  const texts: string[] = [];
  texts.push(`【前回ご予約】(${durationMinute}分) - ¥${price}`);
  for (const name of names) {
    texts.push(`・${name}`);
  }
  return texts;
};
const noStaffID = "no_staff";

const getConfirmMessage = (
  startTime: Date,
  CanceledReservationData: GetCanceledReservationQuery | null,
  isMenuSelected: boolean,
  menuAndCouponNames: string[],
  selectedStaffID: string | null
) => {
  let message = "以下の内容で予約します。よろしいですか？\n\n";
  message += `予約日時: ${formatDateUntilMinute(startTime)} (${getWeekDay(
    startTime
  )})\n`;

  if (selectedStaffID) {
    const selectedStaff =
      CanceledReservationData?.canceledReservation?.salon.availableStaffs.find(
        (staff) => {
          const staffData = getFragmentData(StaffFragmentDoc, staff);
          return staffData.id === selectedStaffID;
        }
      );
    if (!selectedStaff) {
      alert("スタッフが見つかりませんでした");
      return;
    }

    message += `指名スタッフ: ${
      getFragmentData(StaffFragmentDoc, selectedStaff).name
    }\n`;
  } else {
    message += `指名スタッフ: 未指定\n`;
  }

  message += "\n";

  if (isMenuSelected) {
    message += "メニュー:\n";
    for (const menuAndCouponName of menuAndCouponNames) {
      message += `・${menuAndCouponName}\n`;
    }
  } else {
    message += `メニュー: なし\n`;
  }
  return message;
};

const getCompletedPath = (cancelID: string) => {
  return "/rereservation/complete/cancel/" + cancelID;
};

const RereservationPage = () => {
  const cancelID = useParams().cancel_id;
  const apolloClient = useApolloClient();
  const navigate = useNavigate();

  /* states */
  const [selectedStaffID, setSelectedStaffID] = useState<string | null>(null);
  const [isMenuSelected, setIsMenuSelected] = useState<boolean>(false);
  const [CanceledReservationData, setCanceledReservationData] =
    useState<GetCanceledReservationQuery | null>(null); // TODO: useLazyQueryみたいなのがあった気がするから、そっちの方がいいかも

  // fromDate を管理する Set。呼び出し中 or 既に完了した fromDate を記録する. (loadingとかstate使うと更新が遅れて複数回呼ばれてしまう)
  const fetchingFromDatesRef = useRef<Set<string>>(new Set());
  // すでに読み込み中 or 読み込み済みの fromDate かどうかをチェックする関数
  const canFetch = useCallback((fromDateStr: string) => {
    return !fetchingFromDatesRef.current.has(fromDateStr);
  }, []);

  const previousStaff = useMemo(() => {
    return getFragmentData(
      StaffFragmentDoc,
      CanceledReservationData?.canceledReservation.canceledReservation.staff
    );
  }, [CanceledReservationData]);
  const [chunks, setChunks] = useState<DateReservableSlotsFragment[]>([]);
  const [
    getSevenDaysReservableSlotsChunks,
    getSevenDaysReservableSlotsChunksResult,
  ] = useLazyQuery<
    GetSevenDaysReservableSlotsChunksQuery,
    GetSevenDaysReservableSlotsChunksQueryVariables
  >(GetSevenDaysReservableSlotsChunksDocument, {
    fetchPolicy: "no-cache",
    context: {
      fetchOptions: {
        skipLoading: true, // falseだと、state更新タイミングの関係で、再予約日時取得中もこいつ分のloadingが表示されてしまう?
      },
    },
  });
  const selectedMenuDurationMinute = useMemo((): number => {
    // debug用
    // return 30;

    if (isMenuSelected) {
      return (
        CanceledReservationData?.canceledReservation.canceledReservation
          .durationMinute ?? 90000 // 適当にでかい値がdefault
      );
    }
    return (
      CanceledReservationData?.canceledReservation.salon
        .durationMinuteForEmptyMenu ?? 90000
    );
  }, [CanceledReservationData, isMenuSelected]);
  const isOtherStaffSelectedThanCanceledReservation = useMemo(() => {
    return previousStaff && previousStaff.id !== selectedStaffID;
  }, [previousStaff, selectedStaffID]);
  const canReserveCanceledReservationMenusSet = useMemo(() => {
    return (
      (CanceledReservationData?.canceledReservation.canceledReservation
        .canReserveCanceledReservationMenusSet ??
        false) &&
      !isOtherStaffSelectedThanCanceledReservation
    );
  }, [CanceledReservationData, isOtherStaffSelectedThanCanceledReservation]);
  const forceEmptyWhenCannotReserveCanceledReservationMenuSet =
    CanceledReservationData?.canceledReservation.salon
      .forceEmptyWhenCannotReserveCanceledReservationMenuSet ?? true;
  // fromDate に対して一度しかクエリを投げないようにする関数
  const getChunksAndExtend = useCallback(
    (fromDate: Date, cancelID?: string) => {
      if (!cancelID) return;

      const fromDateStr = getGraphQLDateString(fromDate);

      // 既に読み込み済み or 読み込み中の fromDate ならスキップ
      if (!canFetch(fromDateStr)) {
        return;
      }

      // ここで「読み込み中」として記録しておく
      fetchingFromDatesRef.current.add(fromDateStr);

      getSevenDaysReservableSlotsChunks({
        variables: {
          param: {
            fromDate: fromDateStr,
            cancelId: cancelID,
          },
        },
      })
        .then((res) => {
          const gotChunks =
            res.data?.sevenDaysReservableSlotsChunks.chunks.map((chunk) =>
              getFragmentData(DateReservableSlotsFragmentDoc, chunk)
            ) ?? [];
          setChunks((prev) => [...prev, ...gotChunks]);
        })
        .catch((error) => {
          fetchingFromDatesRef.current.delete(fromDateStr);
          console.error(error);
          throw error;
        });
    },
    [canFetch, getSevenDaysReservableSlotsChunks, setChunks]
  );
  const [sevenDaysFrom, setSevenDaysFrom] = useState(new Date());
  useEffect(() => {
    getChunksAndExtend(sevenDaysFrom, cancelID);
  }, [cancelID, getChunksAndExtend, sevenDaysFrom]);
  useEffect(() => {
    if (!cancelID) {
      return;
    }

    apolloClient
      .query<GetCanceledReservationQuery, GetCanceledReservationQueryVariables>(
        {
          query: GetCanceledReservationDocument,
          variables: {
            param: {
              cancelId: cancelID,
            },
          },
          fetchPolicy: "no-cache",
          context: {
            fetchOptions: {
              skipLoading: true,
            },
          },
        }
      )
      .then((res) => {
        if (res.data.canceledReservation.isRereserved) {
          navigate(getCompletedPath(cancelID));
          return;
        }
        const CanceledReservation =
          res.data.canceledReservation.canceledReservation;
        setCanceledReservationData(res.data);
        setIsMenuSelected(
          res.data.canceledReservation.canceledReservation
            .canReserveCanceledReservationMenusSet
        );

        // 前回のスタッフが、現在利用可能(選択肢にある)なら選択する
        // 無理なら、一番上のスタッフを選択する
        const previousStaff = getFragmentData(
          StaffFragmentDoc,
          CanceledReservation.staff
        );

        let previousStaffAvailable = false;
        if (previousStaff) {
          previousStaffAvailable =
            res.data.canceledReservation.salon.availableStaffs.find((staff) => {
              const staffData = getFragmentData(StaffFragmentDoc, staff);
              if (staffData.id === previousStaff.id) {
                return true;
              }
              return false;
            }) !== undefined;
        }

        if (previousStaff && previousStaffAvailable) {
          setSelectedStaffID(previousStaff.id);
        } else {
          setSelectedStaffID(
            getFragmentData(
              StaffFragmentDoc,
              res.data.canceledReservation.salon.availableStaffs[0]
            ).id
          );
        }
      });
  }, [cancelID, apolloClient, navigate]);

  useEffect(() => {
    if (!canReserveCanceledReservationMenusSet) {
      setIsMenuSelected(false);
    }
  }, [canReserveCanceledReservationMenusSet]);

  const cancelConditionsTexts =
    (isMenuSelected
      ? CanceledReservationData?.canceledReservation.salon
          .cancelConditionTextsForMenu
      : CanceledReservationData?.canceledReservation.salon
          .cancelConditionTextsForNoMenu) ?? [];

  const menusAndCoupons =
    CanceledReservationData?.canceledReservation.canceledReservation
      .menusAndCoupons;
  const menuTexts = getMenuNameTexts(
    menusAndCoupons?.names ?? [],
    CanceledReservationData?.canceledReservation.canceledReservation
      .durationMinute,
    CanceledReservationData?.canceledReservation.canceledReservation.price
  );
  const noMenuText = getNoMenuText(
    CanceledReservationData?.canceledReservation.salon
      .durationMinuteForEmptyMenu ?? 0
  );

  const [mutateCreateRereservation] = useMutation<
    CreateRereservationMutation,
    CreateRereservationMutationVariables
  >(CreateRereservationDocument, {});
  const onSelect = useCallback(
    (startTime: Date) => {
      const message = getConfirmMessage(
        startTime,
        CanceledReservationData,
        isMenuSelected,
        menusAndCoupons?.names ?? [],
        selectedStaffID
      );

      if (cancelID && window.confirm(message)) {
        mutateCreateRereservation({
          variables: {
            input: {
              cancelId: cancelID,
              durationMinute: selectedMenuDurationMinute,
              startTime: getJSTISOString(startTime),
              useCanceledReservationMenuSet: isMenuSelected,
              staffId: selectedStaffID,
            },
          },
        }).then(() => {
          navigate(getCompletedPath(cancelID));
        });
      }
    },
    [
      isMenuSelected,
      selectedStaffID,
      CanceledReservationData,
      mutateCreateRereservation,
      menusAndCoupons,
      cancelID,
      selectedMenuDurationMinute,
      navigate,
    ]
  );

  const staffOptions = useMemo(() => {
    const options: Option[] = [];
    if (
      CanceledReservationData?.canceledReservation.salon
        .allowFreeStaffReservation
    ) {
      options.push({ value: noStaffID, texts: ["スタッフ選択なし"] });
    }
    options.push(
      ...(CanceledReservationData?.canceledReservation.salon.availableStaffs.map(
        (staff) => {
          const staffData = getFragmentData(StaffFragmentDoc, staff);
          let name = staffData.name;
          if (staffData.id === previousStaff?.id) {
            name = "【前回ご予約】" + name;
          }
          return { value: staffData.id, texts: [name] };
        }
      ) ?? [])
    );
    return options;
  }, [CanceledReservationData, previousStaff]);

  const menuOptions = useMemo(() => {
    if (canReserveCanceledReservationMenusSet) {
      return [
        { value: "true", texts: menuTexts },
        { value: "false", texts: [noMenuText] },
      ];
    }
    return [{ value: "false", texts: [noMenuText] }];
  }, [canReserveCanceledReservationMenusSet, menuTexts, noMenuText]);

  return (
    <div className={styles.rereservation_page}>
      {CanceledReservationData?.canceledReservation.salon.iconUrl ? (
        <img
          src={CanceledReservationData?.canceledReservation.salon.iconUrl}
          alt="salon_icon"
          style={{ width: "80px" }}
        />
      ) : (
        // space
        <div style={{ height: "80px" }}></div>
      )}
      <div className={styles.header}>ワンクリック再予約フォーム</div>
      <div className={styles.customer_name_and_salon_name}>
        <div className={styles.customer_name}>
          {"お客様名　" +
            (CanceledReservationData?.canceledReservation.canceledReservation
              .customer.name ?? "") +
            "　様"}
        </div>
        <div className={styles.salon_name}>
          {"ご予約店舗名　" +
            (CanceledReservationData?.canceledReservation.salon.name ?? "")}
        </div>
      </div>

      <div className={styles.selector}>
        <div className={styles.attribute}>
          <div className={styles.attribute_header}>■ご予約メニュー（税込）</div>
          <Select
            value={isMenuSelected ? "true" : "false"}
            options={menuOptions}
            onChange={() => setIsMenuSelected(!isMenuSelected)}
          />
        </div>

        <div className={styles.attribute}>
          <div className={styles.attribute_header}>■指名スタッフ</div>
          <Select
            value={selectedStaffID ?? noStaffID}
            options={staffOptions}
            onChange={(value) => {
              if (value === noStaffID) {
                setSelectedStaffID(null);
                return;
              }
              setSelectedStaffID(value);
            }}
          />
        </div>

        <p className={styles.menu_and_staff_caution}>
          「予約メニュー」の指定は、前回ご予約の「指名スタッフ」の場合のみ可能です
        </p>

        {cancelConditionsTexts.length > 0 && (
          <>
            <div className={styles.cancel_policy_header}>
              <div className={styles.title}>キャンセルポリシー</div>
              <div className={styles.caution}>
                ※再予約をすると承諾したとみなします
              </div>
            </div>
            <div className={styles.cancel_policies}>
              {cancelConditionsTexts.map((cancelPolicy) => (
                <div className={styles.cancel_policy_content}>
                  {cancelPolicy}
                </div>
              ))}
            </div>
          </>
        )}
      </div>

      <div className={styles.slots_wrapper}>
        <ReservableSlots
          loading={getSevenDaysReservableSlotsChunksResult.loading}
          chunks={chunks}
          durationMinute={selectedMenuDurationMinute}
          today={new Date()}
          currentStaffID={selectedStaffID}
          onSelectSlot={onSelect}
          loadChunksFrom={getChunksAndExtend}
          sevenDaysFrom={sevenDaysFrom}
          setSevenDaysFrom={setSevenDaysFrom}
          isMenuSelected={isMenuSelected}
          forceEmptyWhenCannotReserveCanceledReservationMenuSet={
            forceEmptyWhenCannotReserveCanceledReservationMenuSet
          }
        />
      </div>
    </div>
  );
};

export default RereservationPage;
